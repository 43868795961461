@keyframes suspension {
    0% {
        position: relative;
        top: 0px;
    }
    50% {
        position: relative;
        top: -0.8rem;
    }
    100% {
        position: relative;
        top: 0px;
    }
}

.rankBox {
    --logo-image: url('https://github.com/idea2app.png');
    --title-background-image: url('https://hackathon-api.static.kaiyuanshe.cn/6342619375fa1817e0f56ce1/2022/10/09/rrrr.png');
    a {
        color: inherit;
        text-decoration: none;
    }
    .image {
        width: 2.1rem;
        &:hover img {
            transform: scale(1.1);
            transition: all 0.3s;
        }
    }
    .title {
        position: relative;
        z-index: 4;
        margin-top: 1.5rem;
        background-image: var(--title-background-image);
        background-size: 21rem auto;
        background-attachment: fixed;
        padding-top: 1.2rem;
        height: 4.8rem;
        &::before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 0.3rem;
            background-color: rgba(161, 160, 152, 0.7);
            width: 100%;
            height: 100%;
            content: '';
        }
        .medal {
            position: absolute;
            top: -1.2rem;
            left: 5%;
            i {
                box-shadow: 0 0 0.5rem 0 #ffd83a;
                border: 0.1rem solid #ffd83a;
                background-image: var(--logo-image),
                    linear-gradient(#ffd83a, #fdeba0);
                background-position: center;
                background-size: 1.5rem 1.5rem;
                background-repeat: no-repeat;
                background-blend-mode: lighten;
                width: 1.7rem;
                height: 1.7rem;
                &:first-child {
                    box-shadow: 0 0 0.5rem 0 #d8d8d8;
                    border-color: #d8d8d8;
                    background-image: var(--logo-image),
                        linear-gradient(#d8d8d8, #eeeeee);
                }
                &:last-child {
                    box-shadow: 0 0 0.5rem 0 #fab36e;
                    border-color: #fab36e;
                    background-image: var(--logo-image),
                        linear-gradient(#fab36e, #fbe7d3);
                }
            }
        }
        h3 {
            position: relative;
            z-index: 2;
            cursor: default;
            width: 10rem;
            color: transparent;
            font-size: 2rem;
            font-family: "'book antiqua', palatino, serif";
            letter-spacing: 0.4rem;
            text-shadow:
                0px 1px 0px #c0c0c0,
                0px 2px 0px #b0b0b0,
                0px 3px 0px #a0a0a0,
                0px 4px 0px #909090,
                0px 5px 10px rgba(0, 0, 0, 0.6);
            &::after {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                margin: 0 auto;
                background-image: linear-gradient(
                    to right,
                    #d8d8d8 10%,
                    #ffd83a 60%,
                    #fab36e 75%
                );
                background-clip: text;
                width: 10rem;
                content: attr(data-text);
                text-shadow: none;
            }
        }
    }
    .topUsers {
        z-index: 5;
        li {
            cursor: default;
            text-align: center;
            .topUser {
                z-index: 3;
                min-height: 6rem;
                letter-spacing: 1px;
            }
            .image {
                z-index: 2;
                animation: 3.5s suspension ease-in-out infinite;
                transition: all 0.6s;
            }
            &:nth-child(1) {
                order: 2;
                padding-top: 0.3rem;
                .topUser {
                    border-bottom: 1.5rem solid #ffd83a;
                    color: #ffd83a;
                    text-shadow:
                        0px 1px 0px #969653,
                        0px 2px 5px rgba(240, 219, 100, 0.4);
                    i {
                        background-image: var(--logo-image),
                            linear-gradient(#ffd83a, #fdeba0);
                        background-position: center;
                        background-size: 2.5rem auto;
                        background-repeat: no-repeat;
                        background-blend-mode: lighten;
                        width: 2.5rem;
                        height: 2.6rem;
                    }
                }
                .image {
                    animation-delay: 3.5s;
                    width: 6.4rem;
                }
            }
            &:nth-child(2) {
                order: 1;
                padding-top: 1rem;
                .topUser {
                    border-bottom: 1.2rem solid #d8d8d8;
                    color: #d8d8d8;
                    text-shadow: 0px 1px 0px #d9cdcd 0px 2px 5px
                        rgba(133, 132, 130, 0.4);
                    i {
                        background-image: var(--logo-image),
                            linear-gradient(#d8d8d8, #eeeeee);
                    }
                }
                .image {
                    animation-delay: 1.4s;
                    width: 6rem;
                }
            }
            &:nth-child(3) {
                order: 3;
                padding-top: 1.5rem;
                .topUser {
                    border-bottom: 1rem solid #fab36e;
                    color: #fab36e;
                    text-shadow:
                        0px 1px 0px #aa8865,
                        0px 2px 5px rgba(167, 129, 67, 0.4);
                    i {
                        background-image: var(--logo-image),
                            linear-gradient(#fab36e, #fbe7d3);
                    }
                }
                .image {
                    animation-delay: 2.4s;
                    width: 5.6rem;
                }
            }
            &:hover .image {
                animation-play-state: paused;
            }
        }
    }
    .restUsers {
        tr {
            &::before {
                display: block;
                position: absolute;
                right: 0;
                bottom: 0;
                transition: all 0.5s;
                background: #9fc2ef;
                width: 0;
                height: 2px;
                content: '';
            }
            &:hover::before {
                left: 0;
                width: 100%;
            }
        }
        td {
            cursor: default;
            background-color: transparent;
            .name {
                font-size: 0;
                &:hover {
                    .image > img {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
}

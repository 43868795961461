@keyframes scrollItem {
    0%,
    10% {
        transform: translateY(0);
    }
    90%,
    100% {
        transform: translateY(-100%);
    }
}
@keyframes scrollWrap {
    0%,
    10% {
        transform: translateY(0);
    }
    90%,
    100% {
        transform: translateY(100%);
    }
}

.scrollWrap {
    --duration: 10s;
    animation: scrollWrap;
    animation-duration: var(--duration);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    font-size: 0;
    &:hover {
        animation-play-state: paused;
        .scrollItem {
            animation-play-state: paused;
        }
    }
    .scrollItem {
        animation: scrollItem;
        animation-duration: var(--duration);
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }
}

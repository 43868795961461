@keyframes _4_3lnG_scrollItem {
  0%, 10% {
    transform: translateX(0);
  }

  90%, 100% {
    transform: translateX(-100%);
  }
}

@keyframes _4_3lnG_scrollWrap {
  0%, 10% {
    transform: translateX(0);
  }

  90%, 100% {
    transform: translateX(100%);
  }
}

._4_3lnG_scrollWrap {
  animation: 4s linear infinite alternate _4_3lnG_scrollWrap;
}

._4_3lnG_scrollWrap ._4_3lnG_scrollItem {
  animation: 4s linear infinite alternate _4_3lnG_scrollItem;
}

._4_3lnG_scrollWrap:hover, ._4_3lnG_scrollWrap:hover ._4_3lnG_scrollItem {
  animation-play-state: paused;
}

._4_3lnG_scrollHeightWrap {
  --scroll-height: 20px;
  height: var(--scroll-height);
}

._4_3lnG_scrollHeightWrap ._4_3lnG_scrollContainer {
  transform: translateY(calc(calc(-100% + var(--scroll-height)  + var(--scroll-height)) * 2));
  max-height: calc(var(--scroll-height) * 3);
}

._4_3lnG_scrollHeightWrap ._4_3lnG_scrollContainer ._4_3lnG_unScrollItem {
  max-height: calc(var(--scroll-height) * 2);
  line-height: var(--scroll-height);
}

._4_3lnG_scrollHeightWrap ._4_3lnG_scrollContainer ._4_3lnG_scrollItem {
  height: var(--scroll-height);
  line-height: var(--scroll-height);
}

.TOztta_timeline {
  max-width: 50%;
  margin: 0 auto;
  position: relative;
}

@media (width <= 991px) {
  .TOztta_timeline {
    max-width: 100%;
  }
}

.TOztta_timeline:after {
  content: "";
  background-color: #fff;
  width: 3px;
  margin-left: -3px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
}

.TOztta_timelineItem {
  background-color: inherit;
  width: 100%;
  position: relative;
}

.TOztta_timelineItem:after {
  z-index: 1;
  content: "";
  background-color: #fff;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 18px;
  right: 1px;
}

.TOztta_right {
  padding: 0 0 20px 40px;
  left: auto;
}

.TOztta_right:before {
  z-index: 1;
  content: " ";
  border: 10px solid #0000;
  border-left-width: 0;
  border-right-color: #fff;
  position: absolute;
  top: 18px;
  left: 30px;
}

.TOztta_right:after {
  left: 0;
}

@keyframes WavURG_suspension {
  0% {
    position: relative;
    top: 0;
  }

  50% {
    position: relative;
    top: -.8rem;
  }

  100% {
    position: relative;
    top: 0;
  }
}

.WavURG_rankBox {
  --logo-image: url("https://github.com/idea2app.png");
  --title-background-image: url("https://hackathon-api.static.kaiyuanshe.cn/6342619375fa1817e0f56ce1/2022/10/09/rrrr.png");
}

.WavURG_rankBox a {
  color: inherit;
  text-decoration: none;
}

.WavURG_rankBox .WavURG_image {
  width: 2.1rem;
}

.WavURG_rankBox .WavURG_image:hover img {
  transition: all .3s;
  transform: scale(1.1);
}

.WavURG_rankBox .WavURG_title {
  z-index: 4;
  background-image: var(--title-background-image);
  background-size: 21rem;
  background-attachment: fixed;
  height: 4.8rem;
  margin-top: 1.5rem;
  padding-top: 1.2rem;
  position: relative;
}

.WavURG_rankBox .WavURG_title:before {
  content: "";
  background-color: #a1a098b3;
  border-radius: .3rem;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.WavURG_rankBox .WavURG_title .WavURG_medal {
  position: absolute;
  top: -1.2rem;
  left: 5%;
}

.WavURG_rankBox .WavURG_title .WavURG_medal i {
  background-image: var(--logo-image), linear-gradient(#ffd83a, #fdeba0);
  background-blend-mode: lighten;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  border: .1rem solid #ffd83a;
  width: 1.7rem;
  height: 1.7rem;
  box-shadow: 0 0 .5rem #ffd83a;
}

.WavURG_rankBox .WavURG_title .WavURG_medal i:first-child {
  background-image: var(--logo-image), linear-gradient(#d8d8d8, #eee);
  border-color: #d8d8d8;
  box-shadow: 0 0 .5rem #d8d8d8;
}

.WavURG_rankBox .WavURG_title .WavURG_medal i:last-child {
  background-image: var(--logo-image), linear-gradient(#fab36e, #fbe7d3);
  border-color: #fab36e;
  box-shadow: 0 0 .5rem #fab36e;
}

.WavURG_rankBox .WavURG_title h3 {
  z-index: 2;
  cursor: default;
  color: #0000;
  letter-spacing: .4rem;
  text-shadow: 0 1px silver, 0 2px #b0b0b0, 0 3px #a0a0a0, 0 4px #909090, 0 5px 10px #0009;
  width: 10rem;
  font-family: "'book antiqua', palatino, serif";
  font-size: 2rem;
  position: relative;
}

.WavURG_rankBox .WavURG_title h3:after {
  z-index: 1;
  content: attr(data-text);
  text-shadow: none;
  background-image: linear-gradient(to right, #d8d8d8 10%, #ffd83a 60%, #fab36e 75%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 10rem;
  margin: 0 auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.WavURG_rankBox .WavURG_topUsers {
  z-index: 5;
}

.WavURG_rankBox .WavURG_topUsers li {
  cursor: default;
  text-align: center;
}

.WavURG_rankBox .WavURG_topUsers li .WavURG_topUser {
  z-index: 3;
  letter-spacing: 1px;
  min-height: 6rem;
}

.WavURG_rankBox .WavURG_topUsers li .WavURG_image {
  z-index: 2;
  transition: all .6s;
  animation: 3.5s ease-in-out infinite WavURG_suspension;
}

.WavURG_rankBox .WavURG_topUsers li:first-child {
  order: 2;
  padding-top: .3rem;
}

.WavURG_rankBox .WavURG_topUsers li:first-child .WavURG_topUser {
  color: #ffd83a;
  text-shadow: 0 1px #969653, 0 2px 5px #f0db6466;
  border-bottom: 1.5rem solid #ffd83a;
}

.WavURG_rankBox .WavURG_topUsers li:first-child .WavURG_topUser i {
  background-image: var(--logo-image), linear-gradient(#ffd83a, #fdeba0);
  background-blend-mode: lighten;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2.5rem;
  width: 2.5rem;
  height: 2.6rem;
}

.WavURG_rankBox .WavURG_topUsers li:first-child .WavURG_image {
  width: 6.4rem;
  animation-delay: 3.5s;
}

.WavURG_rankBox .WavURG_topUsers li:nth-child(2) {
  order: 1;
  padding-top: 1rem;
}

.WavURG_rankBox .WavURG_topUsers li:nth-child(2) .WavURG_topUser {
  color: #d8d8d8;
  text-shadow: 0px 1px 0px #d9cdcd 0px 2px 5px #85848266;
  border-bottom: 1.2rem solid #d8d8d8;
}

.WavURG_rankBox .WavURG_topUsers li:nth-child(2) .WavURG_topUser i {
  background-image: var(--logo-image), linear-gradient(#d8d8d8, #eee);
}

.WavURG_rankBox .WavURG_topUsers li:nth-child(2) .WavURG_image {
  width: 6rem;
  animation-delay: 1.4s;
}

.WavURG_rankBox .WavURG_topUsers li:nth-child(3) {
  order: 3;
  padding-top: 1.5rem;
}

.WavURG_rankBox .WavURG_topUsers li:nth-child(3) .WavURG_topUser {
  color: #fab36e;
  text-shadow: 0 1px #aa8865, 0 2px 5px #a7814366;
  border-bottom: 1rem solid #fab36e;
}

.WavURG_rankBox .WavURG_topUsers li:nth-child(3) .WavURG_topUser i {
  background-image: var(--logo-image), linear-gradient(#fab36e, #fbe7d3);
}

.WavURG_rankBox .WavURG_topUsers li:nth-child(3) .WavURG_image {
  width: 5.6rem;
  animation-delay: 2.4s;
}

.WavURG_rankBox .WavURG_topUsers li:hover .WavURG_image {
  animation-play-state: paused;
}

.WavURG_rankBox .WavURG_restUsers tr:before {
  content: "";
  background: #9fc2ef;
  width: 0;
  height: 2px;
  transition: all .5s;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.WavURG_rankBox .WavURG_restUsers tr:hover:before {
  width: 100%;
  left: 0;
}

.WavURG_rankBox .WavURG_restUsers td {
  cursor: default;
  background-color: #0000;
}

.WavURG_rankBox .WavURG_restUsers td .WavURG_name {
  font-size: 0;
}

.WavURG_rankBox .WavURG_restUsers td .WavURG_name:hover .WavURG_image > img {
  transform: scale(1.2);
}

@keyframes QSMqAq_scrollItem {
  0%, 10% {
    transform: translateY(0);
  }

  90%, 100% {
    transform: translateY(-100%);
  }
}

@keyframes QSMqAq_scrollWrap {
  0%, 10% {
    transform: translateY(0);
  }

  90%, 100% {
    transform: translateY(100%);
  }
}

.QSMqAq_scrollWrap {
  --duration: 10s;
  animation: QSMqAq_scrollWrap;
  animation-duration: var(--duration);
  font-size: 0;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.QSMqAq_scrollWrap:hover, .QSMqAq_scrollWrap:hover .QSMqAq_scrollItem {
  animation-play-state: paused;
}

.QSMqAq_scrollWrap .QSMqAq_scrollItem {
  animation: QSMqAq_scrollItem;
  animation-duration: var(--duration);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
/*# sourceMappingURL=index.css.map */

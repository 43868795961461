.timeline {
    position: relative;
    margin: 0 auto;
    max-width: 50%;
    @media (max-width: 991px) {
        max-width: 100%;
    }
    &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
        margin-left: -3px;
        background-color: #fff;
        width: 3px;
        content: '';
    }
}
.timelineItem {
    position: relative;
    background-color: inherit;
    width: 100%;

    &::after {
        position: absolute;
        top: 18px;
        right: 1px;
        z-index: 1;
        border-radius: 50%;
        background-color: #fff;
        width: 17px;
        height: 17px;
        content: '';
    }
}
.right {
    left: auto;
    padding: 0px 0px 20px 40px;

    &::before {
        position: absolute;
        top: 18px;
        left: 30px;
        z-index: 1;
        border: medium solid #fff;
        border-width: 10px 10px 10px 0;
        border-color: transparent #fff transparent transparent;
        content: ' ';
    }
    &::after {
        left: 0;
    }
}
